.box {
    position: relative;
}

.feedback {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}

.card {
    z-index: 3;
}
