@import '~/bb/ui/Styles/variables';

.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-primary-white);

    & > * {
        max-width: 420px;
    }

    @media (width <= $screen-size-sm) {
        justify-content: flex-end;
    }
}
